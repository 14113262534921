@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.custom-class {
  background-image: url('../src/images/london.webp');
  object-fit: cover;
  height: 100%;
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.custom-image {
padding-bottom: calc(2/3 * 100%);
}

h1, h2, p {
text-align: left;
}


.swiper-button-next {
bottom: 0 !important;
top: unset !important;
width: 43px !important;
border-radius: 100% !important;
color: black !important;
} 

.swiper-button-prev {
bottom: 0 !important;
top: unset !important;
left: unset !important;
right: 25% !important;
width: 43px !important;
border-radius: 100% !important;
color: black !important;
}

.icon-size {
max-width: 5rem;
}

.swiper {
padding-bottom: 5rem !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
font-size: 1rem !important;
font-weight: 800;
}

@media (max-width: 767px) {

.custom-dot::before {
  height: 9px;
  width: 9px;
  background-color: #000000;
  content: "";
  display: block;
  position: absolute;
  left: 32px;
  transform: translate(-4px, -14px);
  margin-bottom: 1px;
}

}


@media (min-width: 768px) {
.custom-dot-one::before {
  height: 9px;
  width: 9px;
  background-color: #000000;
  content: "";
  display: block;
  position: absolute;
 
  transform: translate(-21px, -14px);
  margin-bottom: 1px;
}
}